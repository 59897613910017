<template>
  <div class="iframe-container">
    <meta charset="utf-8" />
    <link
      type="text/css"
      rel="stylesheet"
      href="https://source.zoom.us/2.0.1/css/bootstrap.css"
    />
    <link
      type="text/css"
      rel="stylesheet"
      href="https://source.zoom.us/2.0.1/css/react-select.css"
    />
    <meta name="format-detection" content="telephone=no" />
  </div>
</template>

<script>
import { ZoomMtg } from "@zoomus/websdk";
import { mapState } from "vuex";

ZoomMtg.setZoomJSLib("https://source.zoom.us/2.0.1/lib", "/av");
ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();

console.log(ZoomMtg);
console.log("checkSystemRequirements");
console.log(JSON.stringify(ZoomMtg.checkSystemRequirements()));

export default {
  data: () => ({
    // exit: `${window.location.protocol}//${window.location.host}/student/zoom-ended`,
    exit: `/student/zoom-ended`,
    meetConfig: "",
    signature: "",
    zoom: "",
  }),
  computed: {
    ...mapState("student", {
      meeting: (state) => state.meeting,
      user: (state) => state.user,
    }),
  },
  mounted() {
    let vm = this;
    
    this.$store.dispatch("student/showMeeting", this.$route.params.uuid)
    this.$store.dispatch("student/authAction")
    .then(() => {
      console.log(this.meeting);
      console.log(this.user);
//  + `(${this.$helpers.level(this.user.level)})`
        const password = vm.meeting.password.replace(/\s/g, "");
        const name = this.user.firstname + ' ' + this.user.lastname;
        // Meeting config object
        document.getElementById("zmmtg-root").style.display = "block";

        this.meetConfig = {
          apiKey: this.meeting.admin.zoom_key, //
          apiSecret: this.meeting.admin.zoom_secret,
          meetingNumber: this.meeting.meeting_id,
          userName: name, //
          passWord: password,
          leaveUrl: this.exit,
          role: 0, // 1 - Host 0 - Attendee
        };

        // Generate signature
        this.signature = ZoomMtg.generateSignature({
          meetingNumber: this.meetConfig.meetingNumber,
          apiKey: this.meetConfig.apiKey,
          apiSecret: this.meetConfig.apiSecret,
          role: this.meetConfig.role,
          success: function (res) {
          console.log("success signature: " + res.result);
          },
        });
        /***
         *
         *
         */
        ZoomMtg.init({
          leaveUrl: this.exit,
          isSupportAV: true,
          isSupportBreakout: true,
          showMeetingHeader: true,
          
          meetingInfo: [ // optional
          'topic',
          'host',
        ],
          success: () => {
            ZoomMtg.join({
              meetingNumber: this.meetConfig.meetingNumber,
              userName: this.meetConfig.userName,
              signature: this.signature,
              apiKey: this.meetConfig.apiKey,
              userEmail: this.user.email, //
              passWord: this.meetConfig.passWord,
              success: (res) => {
                ZoomMtg.record({
                  record: true,
                });
                vm.login();
                console.log("Join meeting success " + res.data);
              },
              error: (res) => {
                console.log("Error joining" + res.data);
              },
            });
          },
          error: (res) => {
            console.log("last error" + res.data);
          },
        });

      ZoomMtg.inMeetingServiceListener("onUserJoin", function (data) {
        console.log("inMeetingServiceListener onUserJoin", data);
      });

      ZoomMtg.inMeetingServiceListener("onUserLeave", function (data) {
        /**
         * For typical user
         */
        console.log(data);
      });

      ZoomMtg.inMeetingServiceListener("onUserIsInWaitingRoom", function (data) {
        console.log("inMeetingServiceListener onUserIsInWaitingRoom", data);
      });

      ZoomMtg.inMeetingServiceListener("onMeetingStatus", function (data) {
        /***
         * Meeting status 1 - waiting
         * Meeting status 2 - start
         * Meeting status 3 - not started ended
         *
         */
        console.log(
          "inMeetingServiceListener onMeetingStatus",
          data.meetingStatus
        );
      });
    })
    // console.log(this.$route.params.meeting_id);
    // console.log(this.$route.params.password);
    // console.log(this.$route.params.zoom_key);
    // console.log(this.$route.params.zoom_secret);
    // console.log(this.$route.params.name);
    // console.log(this.$route.params.email);
   
  },
  methods: {
    login() {
      this.$store.dispatch(
        "student/loginMeeting",
        this.meeting.meeting_id
      );
    },
  },
};
</script>